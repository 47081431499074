/*
#***********************************************
#
#      Filename: src/api/merchantList/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 商户优免名单列表
#        Create: 2021-11-23 08:59:58
# Last Modified: 2021-11-26 14:02:51
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加
export function add(data) {
  return request({
    url: '/limitTime/merchant/add',
    method: 'post',
    params: data
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/limitTime/merchant/batchdel',
    method: 'post',
    params: data
  })
}

// 删除
export function deleteItem(id, data) {
  return request({
    url: '/limitTime/merchant/delete/' + id,
    method: 'post',
    params: data
  })
}

// 根据用户ID查找商户
export function getByUid(id) {
  return request({
    url: '/limitTime/merchant/getByUid/' + id,
    method: 'get'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/limitTime/merchant/info/' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/limitTime/merchant/list',
    method: 'get',
    params: data
  })
}

// 查询并创建二维码
export function creatQrcode(data) {
  return request({
    url: '/limitTime/merchant/queryOrCreateQRCode',
    method: 'get',
    params: data
  })
}

// 更新数据
export function update(data) {
  return request({
    url: '/limitTime/merchant/update',
    method: 'post',
    params: data
  })
}

// 商户列表
export function merchantList(id, data) {
  return request({
    url: '/merchant/list/' + id,
    method: 'get',
    params: data
  })
}

// 商户设置优免
export function merchantPromotion(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/consumeLimit/rule/put',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// export function merchantPromotion(data) {
//   const formData = qs.stringify(data)
//   return request({
//     url: '/consumeLimit/rule/put/list',
//     method: 'post',
//     data: formData,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     }
//   })
// }

// 商户设置签约
export function merchantSign(id, data) {
  return request({
    url: '/housing/shop/sign/make/' + id,
    method: 'post',
    data: data
  })
}

// 作废商户与商铺的签约
export function merchantCancel(id, data) {
  return request({
    url: '/housing/shop/sign/cancel/' + id,
    method: 'post',
    params: data
  })
}

// 商铺Tree List
export function shopTreeList(data) {
  return request({
    url: '/housing/shop/tree/' + data.communityId,
    method: 'post',
    params: data
  })
}

// 物业费预计算
export function propertyPreCalc(id, data) {
  return request({
    url: '/property/fee/charge/preCalc/?communityId=' + id,
    method: 'post',
    data: data
  })
}

// 缴纳物业费
export function chargeProperty(id, data) {
  return request({
    url: '/property/fee/charge/submit/?communityId=' + id,
    method: 'post',
    data: data
  })
}

// 租金列表
export function rentalList(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/housing/shop/sign/list/' + id,
    method: 'get',
    params: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 缴纳租金费
export function chargeRental(id, data) {
  return request({
    url: '/housing/shop/rent/charge/submit/' + id,
    method: 'post',
    data: data
  })
}

// 租金预计算
export function rentalPreCalc(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/housing/shop/rent/charge/preCalc/' + id,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
