/*
 *  ┌─────────────────────────────────────────────────────────────┐
 *  │┌───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┬───┐│
 *  ││Esc│!1 │@2 │#3 │$4 │%5 │^6 │&7 │*8 │(9 │)0 │_- │+= │|\ │`~ ││
 *  │├───┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴───┤│
 *  ││ Tab │ Q │ W │ E │ R │ T │ Y │ U │ I │ O │ P │{[ │}] │ BS  ││
 *  │├─────┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴┬──┴─────┤│
 *  ││ Ctrl │ A │ S │ D │ F │ G │ H │ J │ K │ L │: ;│" '│ Enter  ││
 *  │├──────┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴─┬─┴────┬───┤│
 *  ││ Shift  │ Z │ X │ C │ V │ B │ N │ M │< ,│> .│? /│Shift │Fn ││
 *  │└─────┬──┴┬──┴──┬┴───┴───┴───┴───┴───┴──┬┴───┴┬──┴┬─────┴───┘│
 *  │      │Fn │ Alt │         Space         │ Alt │Win│   HHKB   │
 *  │      └───┴─────┴───────────────────────┴─────┴───┘          │
 *  └─────────────────────────────────────────────────────────────┘
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-05-25 16:20:16
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-06-26 22:32:16
 * @FilePath     : \gz-report-front\src\api\fixedParkingSpace\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/fixedParkingSpace/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 固定车位相关API
#        Create: 2021-11-23 10:44:41
# Last Modified: 2021-11-26 15:27:23
#***********************************************
*/
import request from '@/utils/request'

// 添加
export function add(data) {
  return request({
    url: '/vip/create',
    method: 'post',
    contentType: 'application/json;charset=UTF-8',
    data: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/vip/info/' + id,
    method: 'get'
  })
}

// 列表信息
export function list(data) {
  return request({
    url: '/vip/list',
    method: 'get',
    params: data
  })
}

// 根据车位id获取月租信息
export function getInfoByPlaceId(id) {
  return request({
    url: '/vip/info/byPlaceId/' + id,
    method: 'get'
  })
}

// 获取计费明细
export function getRentList(data) {
  return request({
    url: 'vip/order/fixed/list',
    method: 'get',
    params: data
  })
}

export function getExport(data) {
  return request({
    url: 'vip/order/excel/export',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}

// 获取金额统计
export function getStatistic(data) {
  return request({
    url: 'vip/order/stat/periodFixed',
    method: 'get',
    params: data
  })
}

export function getPayStatic(data) {
  return request({
    url: 'vip/order/stat/payFixed',
    method: 'get',
    params: data
  })
}

// 更新月租
export function update(id, data) {
  return request({
    url: '/vip/update/' + id,
    method: 'post',
    data: data
  })
}

// 删除月租
export function deleteItem(id) {
  return request({
    url: '/vip/delete/' + id,
    method: 'post'
  })
}

// 导入批次列表
export function importBatchList(data) {
  return request({
    url: '/vip/importBatch/list',
    method: 'get',
    params: data
  })
}

// 导出错误记录
export function exportErrorRecords(id) {
  return request({
    url: '/vip/importBatch/' + id + '/errorRecords/export',
    method: 'get',
    responseType: 'blob'
  })
}
// 批量删除
export function batchDelete(id) {
  return request({
    url: '/vip/delete/' + id,
    method: 'post'
  })
}

// 获取房间信息
export function geFixedList(id) {
  return request({
    url: '/housing/house/tree/' + id,
    method: 'get'
  })
}

export function getInvoiceStatus(data) {
  return request({
    url: '/vip/order/invoicestatus',
    method: 'get',
    params: data
  })
}

export function getPlateNoList(data) {
  return request({
    url: '/sellinfo/free/place/list',
    method: 'get',
    params: data
  })
}

export function getPlaceTypeChange(data) {
  return request({
    url: '/sellinfo/update/change/place',
    method: 'post',
    params: data
  })
}

export function getParkList(data) {
  return request({
    url: '/parking/place/vacant/place',
    method: 'get',
    params: data
  })
}
