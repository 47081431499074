/*
 *                   ___====-_  _-====___
 *             _--^^^#####//      \\#####^^^--_
 *          _-^##########// (    ) \\##########^-_
 *         -############//  |\^^/|  \\############-
 *       _/############//   (@::@)   \############\_
 *      /#############((     \\//     ))#############\
 *     -###############\\    (oo)    //###############-
 *    -#################\\  / VV \  //#################-
 *   -###################\\/      \//###################-
 *  _#/|##########/\######(   /\   )######/\##########|\#_
 *  |/ |#/\#/\#/\/  \#/\##\  |  |  /##/\#/  \/\#/\#/\#| \|
 *  `  |/  V  V  `   V  \#\| |  | |/#/  V   '  V  V  \|  '
 *     `   `  `      `   / | |  | | \   '      '  '   '
 *                      (  | |  | |  )
 *                     __\ | |  | | /__
 *                    (vvv(VVV)(VVV)vvv)
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *                神兽保佑            永无BUG
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-05-25 17:48:43
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-06-12 20:07:59
 * @FilePath     : \gz-report-front\src\api\vip\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/vip/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租名单
#        Create: 2021-11-17 22:21:51
# Last Modified: 2021-11-26 14:10:12
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加
export function add(data) {
  return request({
    url: '/vip/create',
    method: 'post',
    params: data
  })
}

// 详情
export function info(id) {
  return request({
    url: '/vip/info/' + id,
    method: 'get'
  })
}

// 列表
export function list(data) {
  return request({
    url: '/vip/list',
    method: 'data',
    params: data
  })
}

// 创建月租类型
export function createType(data) {
  const formData = qs.stringify(data)
  return request({
    url: '/setting/vip/type/create',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 月租类型列表
export function typeList(data) {
  return request({
    url: '/setting/vip/type/list',
    method: 'get',
    params: data
  })
}

// 月租类型信息
export function typeInfo(id) {
  return request({
    url: '/setting/vip/type/' + id,
    method: 'get'
  })
}

// 删除月租类型
export function deleteType(id) {
  return request({
    url: '/setting/vip/type/delete/' + id,
    method: 'post'
  })
}

// 修改月租类型
export function updateType(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/setting/vip/type/update/' + id,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 月租报表
export function vipReport(data) {
  return request({
    url: 'report/vip/list',
    method: 'get',
    params: data
  })
}
// 固定车位的批量删除
export function vipBatchDelete(data) {
  return request({
    url: 'vip/batchDelete?vipIds=' + data,
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
