/*
 *                                |~~~~~~~|
 *                                |       |
 *                                |       |
 *                                |       |
 *                                |       |
 *                                |       |
 *     |~.\\\_\~~~~~~~~~~~~~~xx~~~         ~~~~~~~~~~~~~~~~~~~~~/_//;~|
 *     |  \  o \_         ,XXXXX),                         _..-~ o /  |
 *     |    ~~\  ~-.     XXXXX`)))),                 _.--~~   .-~~~   |
 *      ~~~~~~~`\   ~\~~~XXX' _/ ';))     |~~~~~~..-~     _.-~ ~~~~~~~
 *               `\   ~~--`_\~\, ;;;\)__.---.~~~      _.-~
 *                 ~-.       `:;;/;; \          _..-~~
 *                    ~-._      `''        /-~-~
 *                        `\              /  /
 *                          |         ,   | |
 *                           |  '        /  |
 *                            \/;          |
 *                             ;;          |
 *                             `;   .       |
 *                             |~~~-----.....|
 *                            | \             \
 *                           | /\~~--...__    |
 *                           (|  `\       __-\|
 *                           ||    \_   /~    |
 *                           |)     \~-'      |
 *                            |      | \      '
 *                            |      |  \    :
 *                             \     |  |    |
 *                              |    )  (    )
 *                               \  /;  /\  |
 *                               |    |/   |
 *                               |    |   |
 *                                \  .'  ||
 *                                |  |  | |
 *                                (  | |  |
 *                                |   \ \ |
 *                                || o `.)|
 *                                |`\\) |
 *                                |       |
 *                                |       |
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-07-02 08:27:51
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-07-02 22:22:18
 * @FilePath     : \gz-report-front\src\api\parking\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/parking/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 车位管理相关API
#        Create: 2021-11-23 13:48:28
# Last Modified: 2021-11-26 14:04:50
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 添加
export function add(data) {
  const postData = qs.stringify(data, { indices: false })
  return request({
    url: '/parking/place/create',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    data: postData
  })
}

// 删除单条
export function deleteItem(id) {
  return request({
    url: '/parking/place/delete/' + id,
    method: 'post'
  })
}

// 批量删除
export function batchDelete(data) {
  const postData = qs.stringify(data, { indices: false })
  return request({
    url: '/parking/place/batchDelete',
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/parking/place/info/' + id,
    method: 'get'
  })
}

// 列表信息
export function list(data) {
  return request({
    url: '/parking/place/list',
    method: 'get',
    params: data
  })
}

// 更新
export function update(id, data) {
  const postData = qs.stringify(data)
  return request({
    url: '/parking/place/update/' + id,
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取配置信息
export function configInfo(parkingId) {
  return request({
    url: '/parking/config/' + parkingId,
    method: 'get'
  })
}

// 保存收费规则配置
export function putFeeRule(id, data) {
  const postData = qs.stringify(data)
  return request({
    url: '/parking/feeRule/' + id + '/put',
    method: 'post',
    data: postData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 停车场车位类型
export function cqType(data) {
  return request({
    url: '/parking/place/cqType/list',
    method: 'get',
    params: data
  })
}
// 停车场车位类型
export function importBatchList(data, id) {
  return request({
    url: '/parking/place/importBatch/list/' + id,
    method: 'get',
    params: data
  })
}
