/*
 *                        .::::.
 *                      .::::::::.
 *                     :::::::::::
 *                  ..:::::::::::'
 *               '::::::::::::'
 *                 .::::::::::
 *            '::::::::::::::..
 *                 ..::::::::::::.
 *               ``::::::::::::::::
 *                ::::``:::::::::'        .:::.
 *               ::::'   ':::::'       .::::::::.
 *             .::::'      ::::     .:::::::'::::.
 *            .:::'       :::::  .:::::::::' ':::::.
 *           .::'        :::::.:::::::::'      ':::::.
 *          .::'         ::::::::::::::'         ``::::.
 *      ...:::           ::::::::::::'              ``::.
 *     ````':.          ':::::::::'                  ::::..
 *                        '.:::::'                    ':'````..
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-05-25 16:20:16
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-07-02 07:58:35
 * @FilePath     : \gz-report-front\src\api\owner\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/owner/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 业主相关接口
#        Create: 2021-11-10 22:31:36
# Last Modified: 2021-11-28 16:04:26
#***********************************************
*/
import request from '@/utils/request'

// 数据列表
export function list(id, data) {
  return request({
    url: '/housing/owner/list/' + id,
    method: 'get',
    params: data
  })
}

export function simpleList(id, data) {
  return request({
    url: '/housing/owner/list/simple/' + id,
    method: 'get',
    params: data
  })
}

// 业主添加
export function add(data) {
  return request({
    url: '/housing/owner/create/grouping',
    method: 'post',
    data: data
  })
}

// 添加单个业主
export function ownerAdd(data) {
  return request({
    url: '/api/manage/housing/owner/create',
    method: 'post',
    data: data
  })
}

// 删除业主
export function deleteItem(id, data) {
  return request({
    url: '/housing/owner/delete/' + id,
    method: 'post',
    params: data
  })
}

// 编辑业主
export function update(id, data) {
  return request({
    url: '/housing/owner/update/' + id,
    method: 'post',
    data: data
  })
}

// 添加业主
export function create(data) {
  return request({
    url: '/housing/owner/create',
    method: 'post',
    data: data
  })
}

// 更新单个业主
export function ownerUpdate(id, data) {
  return request({
    url: '/api/manage/housing/owner/update/{ownerId}' + id,
    method: 'post',
    data: data
  })
}

// 业主和房屋关系列表
export function relationList(id, data) {
  return request({
    url: '/housing/owner/relation/list/' + id,
    method: 'get',
    params: data
  })
}

// 导入批次列表
export function importBatchList(data) {
  return request({
    url: '/housing/owner/importBatch/list',
    method: 'get',
    params: data
  })
}

// 导出批次失败数据
export function exportErrorRecords(id) {
  return request({
    url: '/housing/owner/importBatch/' + id + '/failRecords/export',
    method: 'get',
    responseType: 'blob'
  })
}
// 选择房间查找业主信息
export function OwnerInformation(id, data) {
  return request({
    url: 'housing/house/info/' + id,
    method: 'get',
    params: data
  })
}

export function OwnerHouseList(data) {
  return request({
    url: 'housing/house/list',
    method: 'get',
    params: data
  })
}

export function OwnerHouse(data) {
  return request({
    url: 'housing/owner/house/list',
    method: 'get',
    params: data
  })
}

export function checkOwnerHouse(data) {
  return request({
    url: 'housing/owner/refHouse/ownerType/switch',
    method: 'post',
    params: data
  })
}

export function deleteHouse(data) {
  return request({
    url: 'housing/owner/relaction/house/delete',
    method: 'get',
    params: data
  })
}

export function OwnerShopList(data) {
  return request({
    url: 'housing/shop/list',
    method: 'get',
    params: data
  })
}

export function checkOwnerShop(data) {
  return request({
    url: 'housing/owner/refShop/ownerType/switch',
    method: 'post',
    params: data
  })
}

export function OwnerShop(data) {
  return request({
    url: 'housing/owner/shop/list',
    method: 'get',
    params: data
  })
}

export function deleteShop(data) {
  return request({
    url: 'housing/owner/relaction/shop/delete',
    method: 'get',
    params: data
  })
}

export function exportExcel(communityId) {
  return request({
    url: `/housing/owner/excel/export/${communityId}`,
    method: 'get',
    responseType: 'blob',
    headers: { 'content-type': 'application/octet-stream' }
  })
}
